import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout/layout';
import LegalLayout from '../components/legalLayout/legalLayout';
import SEO from '../components/seo/seo';

const TermsAndConditionsPage = ({ data }) => {
  return (
    <Layout navbarOnWhite={true}>
      <SEO
        title={data.wpPage.seo.title ?? data.wpPage.title}
        description={data.wpPage.seo.metaDesc ?? data.wpPage.content}
      />

      <LegalLayout title={data.wpPage.title} content={data.wpPage.content} />
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 2 }) {
      title
      content
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

export default TermsAndConditionsPage;
