import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row } from 'react-bootstrap';

import ContentCol from '../contentCol/contentCol';

const LegalLayout = ({ title, content }) => {
  return (
    <main className="py-6">
      <header className="mt-0 mb-4" style={{ minHeight: 'auto' }}>
        <Container>
          <Row className="justify-content-center">
            <ContentCol>
              <h1
                className="text-primary"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </ContentCol>
          </Row>
        </Container>
      </header>

      <section>
        <Container>
          <Row className="justify-content-center">
            <ContentCol>
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </ContentCol>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default LegalLayout;

LegalLayout.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
